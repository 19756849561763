<template>
  <div class="container">
    <el-swiper :src='imgSrc'
               :info='info'></el-swiper>
    <div class="container1">
      <div class="main">
        <div class="part1">
          <div class="title">"盈立方"智慧云基地</div>
          <div class="bgimg"></div>
          <div class="dsc">江苏省常州市武进绿建区牛溪路19号</div>
          <div class="dsc1">服务热线:400-998-8406 业务专线:0519-86900916</div>
          <div class="map"
               ref="baiduMap"
               id="baiduMap"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgSrc: require('@/assets/images/banner2.png'),
      info: {
        title: '关于我们',
        dsc: 'About us',
        number: ''
      }
    };
  },
  created () {
  },
  mounted () {
    this.createMap()

  },
  methods: {
    createMap () {
      let map = new BMap.Map(this.$refs.baiduMap, {
        enableMapClick: false,
        mapType: BMAP_NORMAL_MAP,
      }); // 创建地图实例
      let point = new BMap.Point(119.89913, 31.70680)
      map.setCurrentCity("常州市"); // 设置地图显示的城市 此项是必须设置的
      map.centerAndZoom(point, 14); // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom();//开启滚轮缩放
      let iconUrl = require('@/assets/bpmap/lot-normal.png')
      var myicon = new BMap.Icon(iconUrl, new BMap.Size(30, 41));
      var marker = new BMap.Marker(
        point,
        {
          icon: myicon,
        }
      )
      map.addOverlay(marker);
    }

  },
}
</script>

<style lang='scss' scoped>
@import "@/assets/styles/contact-us.scss";
</style>